import React, { PureComponent } from 'react';
import { useNavigate } from 'react-router-dom'

const Button = (props) => {
	const navigate = useNavigate();

	let btnClass = 'btn-default';
	if (props.primary) btnClass = 'btn-primary';
	if (props.secondary) btnClass = 'btn-secondary';
	if (props.success) btnClass = 'btn-success';
	if (props.light) btnClass = 'btn-light';
	if (props.dark) btnClass = 'btn-dark';	
	if (props.info) btnClass = 'btn-info';
	if (props.warning) btnClass = 'btn-warning';
	if (props.danger) btnClass = 'btn-danger';

	if (props.outline) btnClass = btnClass.replace('btn', 'btn-outline');

	if (props.disabled) btnClass += ' disabled';
	if (props.block) btnClass += ' btn-block';
	if (props.small) btnClass += ' btn-sm';
	else if (props.xs) btnClass += ' btn-xs';
	if (props.spaced) btnClass += ' mx-' + props.spaced;

	let onClick = (props.onClick) ? props.onClick : () => null;
	if (props.to) {
		onClick = () => navigate(props.to);
	}

	let submit = props.type === "submit";

	return (submit ?
		<button type="submit" onClick={onClick} className={'btn' + (btnClass ? ' ' + btnClass : '') + (props.className ? ' ' + props.className : '')}>{props.children}</button>
		:
		<div onClick={onClick} className={'btn' + (btnClass ? ' ' + btnClass : '') + (props.className ? ' ' + props.className : '')}>{props.children}</div>)
}

export default Button;