import t from '../resources/i18n';
import { IMAGES_BUCKET } from './Api';

export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const deliveryServices = [
	'Deliveroo',
	'Uber',
	'Just_Eat',
	'Restaurant',
	'Mishloha',
	'Wolt',
	'Ten_Bis',
	'Grubhub',
	'Doordash',
	'Instacart',
	'Deliverycom',
	'goPuff',
	'ChowNow',
	'Seamless',
	'Postmates',
];

export const tableReservationPlaces = [
	'Ontopo',
	'OpenTable',
	'Resy',
	'EatApp',
	'YelpReservation',
	'Tock',
	'Tablein',
	'Wisely',
	'Restaurant',
	'None',
];
export const cachedRoutes = [
	{ name: 'specific restaurant (id)', url: '/get-app-restaurant/_id' },
	{ name: 'specific chain (id)', url: '/get-app-chain/_id' },
	{ name: 'specific recipe (id)', url: '/get-app-recipe/_id' },
	{ name: 'specific business branch (id)', url: '/get-app-business-branch/_id' },
	{ name: 'all restaurants by country (country)', url: '/restaurants-by-country?country=_country' },
	{ name: 'all chains by country (country)', url: '/chains-by-country?country=_country' },
	{ name: 'all businesses by country (country)', url: '/businesses-by-country?country=_country' },
	{ name: 'all recipes by country (country)', url: '/recipes-by-country?country=_country' },
	{ name: 'all companies by country (country)', url: '/companies-by-country?country=_country' },
	{ name: 'all business branches', url: '/get-business-branches' },
	{
		name: 'all website entities by country and locale (country,locale)',
		url: '/website-entities-by-country-and-locale?country=_country&locale=_locale',
	},
	{
		name: 'all banners by country and locale (country,locale)',
		url: '/banners-by-country-and-locale?country=_country&locale=_locale',
	},
	{
		name: 'all website news by country and locale (country,locale)',
		url: '/website-news-by-country-and-locale?country=_country&locale=_locale',
	},
	{ name: 'all review hash tags', url: '/get-review-hash-tags' },
	{
		name: 'all instagram website posts by country (country)',
		url: '/get-instagram-website-posts?country=_country',
	},
	{
		name: 'all geographic regions by country (country)',
		url: '/get-geographic-regions?country=_country',
	},
	{ name: 'all recipe category', url: '/get-recipe-category' },
	{ name: 'all restaurant category', url: '/get-restaurant-category' },
	{ name: 'all business category', url: '/get-business-category' },
	{ name: 'all business subcategory', url: '/get-business-subcategory' },
	{ name: 'all companies subcategory', url: '/get-prod-business-subcat' },
	{ name: 'all tags', url: '/get-tags' },
	{
		name: 'all benefits by country and locale (country,locale)',
		url: '/get-benefits?country=_country&locale=_locale',
	},

];

export const getOpeningDays = (from, to) => {
	const weekDays = [
		t.t('days.sun'),
		t.t('days.mon'),
		t.t('days.tue'),
		t.t('days.wed'),
		t.t('days.thur'),
		t.t('days.fri'),
		t.t('days.sat'),
	];
	if (from === to) {
		return weekDays[from];
	}

	if (to - from > 1 || from > to) return `${weekDays[from]} - ${weekDays[to]}`;

	if (to - from === 1) return `${weekDays[from]}, ${weekDays[to]}`;
};
export const getOpeningShifts = (shifts) => {
	return shifts
		? shifts
			.map((shift) => {
				const from = shift.from ? shift.from.replace(':00.000', '') : '';
				const to = shift.to ? shift.to.replace(':00.000', '') : '';

				return `${from} - ${to}`;
			})
			.join('\n')
		: '';
};

export const getDisplayHours = (openings) => {
	// console.log('got these openings: ', openings);
	let openingStrings = [];
	let openingDays;
	for (let o of openings) {
		openingDays = getOpeningDays(o[0], o[1]) + ': ' + o[2] + '-' + o[3];
		openingStrings.push(openingDays);
	}

	return openingStrings.join(', ');
};

export const getDisplayMenu = (menu) => {
	if (!menu) return '-';
	else return 'menu';
};

export const getTagNames = (tagIds, tags = []) => {
	return tags
		.filter((tag) => tagIds.includes(tag.id))
		.map((t) => t.name)
		.join(', ');
};

export const getCategoryNames = (catIds, categories) => {
	return categories
		.filter((r) => catIds.includes(r.id))
		.map((r) => r.name)
		.join(', ');
};

export const getReservationName = (name) => {
	if (!name) return null;

	if (tableReservationPlaces.includes(name)) return name;
	return 'Other';
};

const generateSlug = (name) => {
	const slug = name ? name.toLowerCase().replace(/\s+/g, '-') : '';
	
	const cleanSlug = slug.replace(/[^a-z0-9-]/g, '');
	const randomNumber = Math.floor(Math.random() * 1000);
	return cleanSlug + "-" + randomNumber;
  }

export const getRestaurantObjectFromExcelLine = (line, tags, restaurantCategories, excelType) => {
	let restaurant = { approved: true };
	const restaurant_tags = [];
	const rest_categories = [];

	let error_restaurant_categories = false,
		error_schedule = false,
		error_location = false;
	let wrong_categories = [];

	if (excelType === 'chain') {
		restaurant['name'] = line['name'] ? line['name'] : line['Name'] ? line['Name'] : '';
	} else {
		restaurant['restaurant_name'] = line['name'] ? line['name'] : line['Name'] ? line['Name'] : '';
	}

	const slug =  generateSlug(excelType === 'chain' ? restaurant['name'] : restaurant['restaurant_name']);

	restaurant['slug'] = slug;
	restaurant['delivery_services'] = [];
	restaurant['description'] = line.description;
	restaurant['search_names'] = line.search_names;
	restaurant['public_email'] = line.email;
	restaurant['tag_line'] = line.tag_line;
	restaurant['website_url'] = line.website_url;
	restaurant['instagram_url'] = line.instagram_url;
	restaurant['facebook_url'] = line.facebook_url
		? line.facebook_url
			.replace('m.facebook', 'facebook')
			.replace('web.facebook', 'facebook')
			.replace('mobile.facebook', 'facebook')
		: '';
	restaurant['reservation_url'] = line.reservation_url;
	restaurant['reservation_name'] = getReservationName(line.reservation_name);
	restaurant['long_description'] = line.long_description;

	restaurant['country'] = line.country;
	restaurant['state'] = line.state;

	if (excelType !== 'chain') {
		restaurant['address'] = line.google_address;
		restaurant['phone_number'] = line.phone_number;
		restaurant['chain_name'] = line['Chain Name'] ? line['Chain Name'].trim() : null;
	}

	for (let deliveryService of deliveryServices) {
		const deliveryServiceName =
			deliveryService === 'Restaurant' ? 'restaurant_delivery' : deliveryService;

		if (line[deliveryServiceName] && line[deliveryServiceName].length > 0) {
			restaurant['delivery_services'].push({
				delivery: deliveryService,
				url: line[deliveryServiceName],
			});
		}
	}

	if (line.restaurant_categories) {
		for (let category of line.restaurant_categories.split(',')) {
			let categoryObject = restaurantCategories.find((c) => c.name === category.trim());
			if (categoryObject) rest_categories.push(categoryObject);
			else {
				wrong_categories.push(category);
			}
		}
	}

	if (
		line.restaurant_categories &&
		rest_categories.length < line.restaurant_categories.split(',').length
	) {
		error_restaurant_categories = true;
	}

	
	if (line.vegan == "TRUE" || (typeof(line.vegan) === "boolean" && line.vegan)) restaurant_tags.push(tags.find((t) => t.name === 'Vegan'));
	console.log('line: ', line, JSON.stringify(restaurant_tags));
	if (line.gluten_free == "TRUE" || (typeof(line.gluten_free) === "boolean" && line.gluten_free)) restaurant_tags.push(tags.find((t) => t.name === 'Gluten Free'));
	if (line.animal_friendly) restaurant_tags.push(tags.find((t) => t.name === 'Animal Friendly'));
	if (line.kosher) restaurant_tags.push(tags.find((t) => t.name === 'Kosher'));
	if (line.wheelchair_accessible)
		restaurant_tags.push(tags.find((t) => t.name === 'Wheelchair Accessible'));
	if (line.organic) restaurant_tags.push(tags.find((t) => t.name === 'Organic'));

	restaurant.tags = restaurant_tags;
	restaurant.rest_categories = rest_categories;

	console.log('restaurant tags: ', restaurant_tags);

	restaurant.schedule = getScheduleFromExcel(line.schedule);
	restaurant.location = getLocationFromExcel(line.location, line.state);

	if (!restaurant.schedule) error_schedule = true;
	if (!restaurant.location) error_location = true;

	restaurant.foodPicsUrls = getFoodPicsFromExcel(line);
	restaurant.logoUrl = line.logo;

	restaurant.google_place_id = line.google_place_id;
	console.log('restaurant.foodPicsUrls', restaurant.foodPicsUrls);
	return {
		restaurant,
		error: false,
		error_restaurant_categories,
		error_schedule,
		error_location,
		wrong_categories,
	};
};

export const getScheduleFromExcel = (schedule) => {
	if (!schedule || schedule === 'None') return null;
	if (schedule === '24H') return { always_open: true };

	const dailyOpenings = [];
	for (let shift of schedule.trim().split(';')) {
		if (shift.length < 2) continue;

		let fromToString = shift.split(':')[0].trim();
		let hoursString = shift.slice(shift.indexOf(':') + 1);

		const from_day = fromToString.includes('-')
			? weekDays.indexOf(fromToString.split('-')[0].trim())
			: weekDays.indexOf(fromToString.trim());
		const to_day = fromToString.includes('-')
			? weekDays.indexOf(fromToString.split('-')[1].trim())
			: weekDays.indexOf(fromToString.trim());

		console.log('hoursString', hoursString);
		let from = hoursString.split('-')[0].trim() + ':00.000';
		let to = hoursString.split('-')[1].trim() + ':00.000';

		dailyOpenings.push({ from_day, to_day, from, to });
	}

	return { daily_opening: dailyOpenings };
};

export const getLocationFromExcel = (location, state) => {
	if (!location) return null;
	const [lon, lat, timezone] = location.split(',');
	if (!lon || !lat || !timezone) return null;
	return { lon, lat, timezone, state: state.toUpperCase() };
};

export const getFoodPicsFromExcel = (line) => {
	const foodPicsUrls = [];

	line['Restaurant picture']
		? foodPicsUrls.push(line['Restaurant picture'])
		: foodPicsUrls.push(null);
	line['Food picture 1'] ? foodPicsUrls.push(line['Food picture 1']) : foodPicsUrls.push(null);
	line['Food picture 2'] ? foodPicsUrls.push(line['Food picture 2']) : foodPicsUrls.push(null);
	line['Food picture 3'] ? foodPicsUrls.push(line['Food picture 3']) : foodPicsUrls.push(null);

	return foodPicsUrls;
};

export const restaurantColumnDefs = [
	{ field: 'restaurant_name', filter: true, sortable: true },
	{ field: 'id', filter: true, sortable: true },
	{ field: 'address', filter: true, sortable: true },
	{ field: 'chain_name', filter: true, sortable: true },
	{ field: 'chain_id', filter: true, sortable: true },
	{ field: 'last_update', sortable: true },
	{ field: 'days_since_last_update', sortable: true },
	{ field: 'waiting_for_reply', filter: true },
	{ field: 'date_of_last_contact', sortable: true },
	{ field: 'description', filter: true, sortable: true },
	{ field: 'tags' },
	{ field: 'Images' },
	{ field: 'schedule' },
	{ field: 'label_approved', filter: true },
	{ field: 'restaurant_categories', filter: true },
	{ field: 'website_url', filter: true, sortable: true },
	{ field: 'facebook_url', filter: true, sortable: true },
	{ field: 'instagram_url', filter: true, sortable: true },
	{ field: 'public_email', filter: true, sortable: true },
	{ field: 'search_names', filter: true, sortable: true },
	{ field: 'linkedin_url', filter: true, sortable: true },
	{ field: 'google_place_id', filter: true, sortable: true },
	{ field: 'slug', filter: true, sortable: true },
	{ field: 'cover_photo', filter: true, sortable: true },
	{ field: 'location_lon', filter: true, sortable: true },
	{ field: 'location_lat', filter: true, sortable: true },
	{ field: 'country', filter: true, sortable: true },
	{ field: 'geo_region', filter: true, sortable: true },
	{ field: 'delivery_services_1_delivery', filter: true, sortable: true },
	{ field: 'delivery_services_1_url', filter: true, sortable: true },
	{ field: 'delivery_services_2_delivery', filter: true, sortable: true },
	{ field: 'delivery_services_2_url', filter: true, sortable: true },
	{ field: 'delivery_services_3_delivery', filter: true, sortable: true },
	{ field: 'delivery_services_3_url', filter: true, sortable: true },
	{ field: 'delivery_services_4_delivery', filter: true, sortable: true },
	{ field: 'delivery_services_4_url', filter: true, sortable: true },
	{ field: 'delivery_services_5_delivery', filter: true, sortable: true },
	{ field: 'delivery_services_5_url', filter: true, sortable: true },
	{ field: 'delivery_services_6_delivery', filter: true, sortable: true },
	{ field: 'delivery_services_6_url', filter: true, sortable: true },
	{ field: 'reservation_name', filter: true, sortable: true },
	{ field: 'reservation_url', filter: true, sortable: true },
	{ field: 'menu', filter: true, sortable: true },
];
export const chainsColumnDefs = [
	{ field: 'id', filter: true, sortable: true },
	{ field: 'name', filter: true, sortable: true },
	{ field: 'description', filter: true, sortable: true },
	{ field: 'tags' },
	{ field: 'Images' },
	{ field: 'rest_categories', filter: true },
	{ field: 'website_url', filter: true, sortable: true },
	{ field: 'facebook_url', filter: true, sortable: true },
	{ field: 'instagram_url', filter: true, sortable: true },
];

export const businessColumnDefs = [
	{ field: 'name', filter: true, sortable: true },
	{ field: 'last_update', sortable: true },
	{ field: 'days_since_last_update', sortable: true },
	{ field: 'waiting_for_reply', filter: true },
	{ field: 'date_of_last_contact', sortable: true },
	{ field: 'description', filter: true, sortable: true },
	{ field: 'tags' },
	{ field: 'label_approved', filter: true },
	{ field: 'business_categories', filter: true },
	{ field: 'business_subcategories', filter: true },
];

export const getPreparedEntity = (r, tags, categories, subcategories = []) => {
	return {
		id: r.id,
		restaurant_name: r.restaurant_name,
		name: r.name,
		chain_name: r.chainName,
		chain_id: r.chainId,
		Images: r.Images?.join("\n") ?? [],
		last_update: r.last_update,
		email: r.email,
		public_email: r.public_email,
		search_names: r.search_names,
		linkedin_url: r.linkedin_url,
		google_place_id: r.google_place_id,
		slug: r.slug,
		cover_photo: r.cover_photo && (IMAGES_BUCKET + r.cover_photo.url),
		location_lon: r.location?.lon,
		location_lat: r.location?.lat,
		// approved: r.approved, //not needed as we only export approved restaurants
		// deleted: r.deleted, //not in use
		contact: r.contact, //TODO needed ??
		// reviewList: r.reviewList, //TODO needed ??
		country: r.country,
		geo_region: r.geo_region,
		delivery_services_1_delivery: r.delivery_services[0]?.delivery,
		delivery_services_1_url: r.delivery_services[0]?.url,
		delivery_services_2_delivery: r.delivery_services[1]?.delivery,
		delivery_services_2_url: r.delivery_services[1]?.url,
		delivery_services_3_delivery: r.delivery_services[2]?.delivery,
		delivery_services_3_url: r.delivery_services[2]?.url,
		delivery_services_4_delivery: r.delivery_services[3]?.delivery,
		delivery_services_4_url: r.delivery_services[3]?.url,
		delivery_services_5_delivery: r.delivery_services[4]?.delivery,
		delivery_services_5_url: r.delivery_services[4]?.url,
		delivery_services_6_delivery: r.delivery_services[5]?.delivery,
		delivery_services_6_url: r.delivery_services[5]?.url,
		tag_line: r.tag_line,
		date_of_last_contact: r.date_of_last_contact,
		days_since_last_update: Math.floor(
			(new Date().getTime() - new Date(r.last_update).getTime()) / 1000 / 3600 / 24
		),
		waiting_for_reply:
			r.date_of_last_contact && r.last_update < r.date_of_last_contact ? 'true' : 'false',
		address: r.address,
		phone_number: r.phone_number,
		discount_list:
			r.discount_list && r.discount_list.length > 0
				? r.discount_list.map((d) => d.description).join(', ')
				: null,
		discount: r.discount ? r.discount.description : '',
		description: r.description,
		label_approved: r.label_approved,
		restaurant_categories: r.rest_categories ? getCategoryNames(r.rest_categories, categories) : '',
		rest_categories_ids: r.rest_categories,
		business_categories: r.categories ? getCategoryNames(r.categories, categories) : '',
		business_subcategories: r.business_subcats
			? getCategoryNames(r.business_subcats, subcategories)
			: '',
		geographic_regions_ids: r.geographic_regions,
		business_categories_ids: r.business_categories,
		business_subcategories_ids: r.business_subcats,
		is_online: r.is_online,
		discount_type: r.discount_list ? r.discount_list.map((d) => d.discount_type).join(', ') : null,
		tags: getTagNames(r.tags, tags),
		tag_ids: r.tags,
		menu: r.labelled?.menu && JSON.stringify(r.labelled.menu, null, 2),
		schedule:
			r.schedule && r.schedule.daily_opening ? getDisplayHours(r.schedule.daily_opening) : '',
		instagram_url: r.instagram_url,
		tiktok_url: r.tiktok_url,
		facebook_url: r.facebook_url,
		website_url: r.website_url,
		reservation_name: r.reservation_name,
		reservation_url: r.reservation_url,
		logo: r.logo,
		branches: r.branches,
	};
};

export const arrayWithoutDuplicates = (arr, key) => {
	if (!arr || !key) return [];
	console.log('before removing duplications: ', arr.length);
	const newArr = [];

	for (let element of arr) {
		if (!newArr.map((r) => r[key]).includes(element[key])) newArr.push(element);
	}
	console.log('after removing duplications: ', newArr.length);

	return newArr;
};

export const getCachedRecords = () => [
	'restaurants-by-country?country=IL',
	'restaurants-by-country?country=GB',
	'restaurants-by-country?country=US',
	'chains-by-country?country=IL',
	'chains-by-country?country=GB',
	'chains-by-country?country=US',
	'businesses-by-country?country=IL',
	'businesses-by-country?country=GB',
	'businesses-by-country?country=US',
	'recipes-by-country?country=IL',
	'recipes-by-country?country=GB',
	'recipes-by-country?country=US',
	'get-business-branches',
	'website-entities-by-country-and-locale?country=IL&locale=he-IL',
	'website-entities-by-country-and-locale?country=GB&locale=en',
	'website-entities-by-country-and-locale?country=US&locale=en',
	'banners-by-country-and-locale?country=IL&locale=he-IL',
	'banners-by-country-and-locale?country=GB&locale=en',
	'banners-by-country-and-locale?country=US&locale=en',
	'website-news-by-country-and-locale?country=IL&locale=he-IL',
	'website-news-by-country-and-locale?country=GB&locale=en',
	'website-news-by-country-and-locale?country=US&locale=en',
	'get-instagram-website-posts?country=IL',
	'get-instagram-website-posts?country=GB',
	'get-instagram-website-posts?country=US',
	'get-geographic-regions?country=IL',
	'get-geographic-regions?country=GB',
	'get-geographic-regions?country=US',
	'get-review-hash-tags',
	'get-tags',
	'get-business-subcategories',
	'get-restaurant-categories',
	'get-business-categories',
	'get-recipe-categories',
];
