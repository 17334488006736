import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import * as loginActions from '../actions/loginActions';
import * as reviewsActions from '../actions/reviewsActions';
import Button from '../components/utils/Button';
import Text from '../components/utils/Text';
import { Api, BASE_API } from '../resources/Api';
import { cachedRoutes } from '../resources/Utils';
import t from '../resources/i18n';

const countries = ['IL', 'GB', 'US'];
const locales = ['he-IL', 'en'];

const routes = [
	{
		values: ['get-app-custom-restaurant-list'], // ?banner_id = 15
	},
];

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
}

let _headers = {
	'Content-Type': 'application/json',
	Accept: 'application/json',

};
_headers['Authorization'] = 'Bearer ' + Api._credentials.hash;
const headers = Object.assign({}, _headers);

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			authorization: Api._credentials.hash ? `Bearer ${Api._credentials.hash}` : "",
		}
	}
});
const httpLink = createHttpLink({
	uri: BASE_API + '/graphql'
});

const client = new ApolloClient({
	cache: new InMemoryCache(),
	defaultOptions: defaultOptions,
	link: authLink.concat(httpLink),

});
class CacheManagement extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			path: '',
			selectedRoute: '',
			isSingleChain: false,
			chainRestaurantsIds: [],
			params: {
				_locale: '',
				_country: '',
				_id: '',
				_entityName: '',
			},
		};
	}


	async componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);
	}

	onChangeParams() {
		let path = this.state.selectedRoute;
		for (const param of Object.keys(this.state.params)) {
			path = path.replace(param, this.state.params[param]);
		}
		this.setState({ path }, () => console.log('path:    ', path));
	}
	async onClickResetCache() {
		const { path, selectedRoute, isSingleChain } = this.state;
		this.setState({ loading: true });
		if (isSingleChain) {
			client
				.query({
					query: gql`query {
						chain(id: ${this.state.params._id}) {
							data {
						id
						attributes {
						  name
						  restaurants{
							data{
							  id
							 
							}
						  }
						  
						}
					  }
					}
				  
				}`,
				})
				.then((result) => {
					this.setState(
						{ chainRestaurantsIds: result?.data?.chain?.data?.attributes?.restaurants?.data?.map(r => r.id) },
						async () => {
							console.log('chainRestaurantsIds: ', this.state.chainRestaurantsIds);
							const res = await Api.clearCachedMethod(`/chains-by-country?country=${this.state.params._country}`)
							res && console.log('chain res:   ', res, this.state.params._country);
							this.state.chainRestaurantsIds.forEach(async id => {
								const res = await Api.clearCachedMethod(`/get-app-restaurant/${id}`);
								res && console.log('chain restaurants res:   ', res);
							})
							this.setState({ loading: false });
						}

					)

				})
		} else {

			const res = await Api.clearCachedMethod(path);
			res && console.log('res:   ', res);
		}
	}

	render() {
		const { _id, appLocale, appCountry, loading } = this.state.params;
		return (
			<div
				style={ {
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				} }
			>
				<h1>Cache Management</h1>
				<Select
					options={ cachedRoutes.map((r) => ({ label: r.name, value: r.url })) }
					onChange={ (item) => {
						console.log('change!', item.label);
						this.setState({ isSingleChain: item.label === 'specific chain (id)', selectedRoute: item.value, path: item.value }, () =>
							console.log(this.state.selectedRoute, item.label)
						)
					}
					}
					placeholder="Select route"
				/>
				<input
					value={ _id }
					type={ 'number' }
					onChange={ (e) =>
						this.setState({ params: { ...this.state.params, _id: e.target.value } }, () =>
							this.onChangeParams()
						)
					}
					placeholder={ 'id' }
				/>
				<Select
					options={ countries.map((c) => ({ label: c, value: c })) }
					onChange={ (e) =>
						this.setState({ params: { ...this.state.params, _country: e.value } }, () =>
							this.onChangeParams()
						)
					}
					placeholder="Select country"
				/>
				<Select
					options={ locales.map((l) => ({ label: l, value: l })) }
					onChange={ (e) =>
						this.setState({ params: { ...this.state.params, _locale: e.value } }, () =>
							this.onChangeParams()
						)
					}
					placeholder="Select locale"
				/>
				<Text style={ { margin: '20px' } }>
					{ this.state.path ? this.state.path : this.state.selectedRoute }
				</Text>
				<Button disabled={ loading } success={ 1 } onClick={ () => this.onClickResetCache() }>
					{ 'RESET' }
				</Button>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return { login: state.login, menus: state.menus, reviews: state.reviews };
};

const mapDispatchToProps = (dispatch) => {
	return {
		loginActions: bindActionCreators({ ...loginActions }, dispatch),
		reviewsActions: bindActionCreators({ ...reviewsActions }, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CacheManagement);
