import React, { Component } from 'react';
import { Column, Row } from "../utils/Row";
import Button from "../utils/Button";
import Icon from "../utils/Icon";
import Confirmation from "../utils/Confirmation";
import Text from "../utils/Text";
import { DateTimeFormat, UserStatus } from "../../resources/Filters";
import Select from 'react-select';
export default class UserRow extends Component {

	constructor() {
		super();
		this.state = {
			deleting: false,
			selectedBenefitId: null
		}
	}

	toggleDelete = () => {
		this.setState({ deleting: !this.state.deleting });
	};

	onDeleteUser = () => {
		this.props.onDeleteUser();
	};

	onSignOutSubscriber = () => {
		this.props.onSignOutSubscriber();
	};



	render() {

		const { user, benefits } = this.props;
		const { deleting, selectedBenefitId } = this.state;

		console.log('selected benefit id: ', selectedBenefitId);

		return (

			<Row centered={1}>
				<Column className="col-md text-right small">
						<div className={'text-secondary'}>{user.id}</div>
				</Column>
				<Column className="col-lg">
					<Select options={benefits.map(b => ({ value: b.id, label: b.title }))} onChange={item => this.setState({ selectedBenefitId: item.value })} placeholder="בחרו הטבה" />
				</Column>
				<Column className="col-md">
					<Button spaced={1} onClick={() => this.props.onEmailMonthlyDiscount(selectedBenefitId, user.hash)}>
						<Text>users.sendMonthlyDiscount</Text>
					</Button>
				</Column>
				<Column className="col-md"><span className={(user.searchMatch && (user.searchMatch.indexOf("first_name") > -1 || user.searchMatch.indexOf("full_name") > -1) ? ' text-primary font-weight-bold' : 'text-secondary')}>{user.first_name}</span> <span className={(user.searchMatch && (user.searchMatch.indexOf("last_name") > -1 || user.searchMatch.indexOf("full_name") > -1) ? ' text-primary font-weight-bold' : '')}>{user.last_name}</span></Column>
				<Column className="col-md">
					<div>
						<Icon className={'mr-1 ' + (user.status === "subscriber" ? 'text-success' : user.status === "regular" ? 'text-info' : 'text-primary')}>
							{user.status === "subscriber" ? 'smile' : user.status === "regular" ? 'meh' : 'frown'}
						</Icon>
						{UserStatus(user.status)}
					</div>
				</Column>
				<Column className="col-md text-right small">
					<div className={(user.searchMatch && user.searchMatch.indexOf("email") > -1 ? ' text-primary font-weight-bold' : 'text-secondary')}>{user.email}</div>
				</Column>
				<Column className="col-md text-right small">
					<div className={(user.searchMatch && user.searchMatch.indexOf("installation_id") > -1 ? ' text-primary font-weight-bold' : 'text-secondary')}>{'installation id: ' + user.installation_id}</div>
				</Column>
				<Column className="col-md text-right small">
					<div className={'text-secondary'}>{user.model_name}</div>
				</Column>	
				<Column className="col-md text-right small">
					<div className={user.has_push_token ?  ' text-primary font-weight-bold' : 'text-secondary'}>{`Has push token? ${user.has_push_token}`}</div>
				</Column>
			</Row>
		);
	}
}
	